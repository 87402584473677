<template>
  <div class="fragment">
    <div v-if="isLoading" class="loader">
      <v-progress-circular indeterminate color="#000"></v-progress-circular>
    </div>
    <a v-show="!isLoading" class="again" @click="$router.push('/scan')"
      ><span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-maximize"
        >
          <path
            d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"
          ></path></svg
      ></span>
      Scanner à nouveau</a
    >
    <!--
    <Map @mounted="flyTo" ref="mapComponent" :displayOverlay="false"></Map> -->
    <div v-show="!isLoading" class="scan-result">
      <div :class="isReduce ? 'result hide' : 'result show'">
        <!-- <div class="center top">
          <a @click="isReduce = !isReduce">{{
            isReduce ? "Afficher les informations" : "Voir la carte"
          }}</a>
        </div> -->
        <div class="result-header">
          <div v-if="type === 'connector'" class="plug">
            <span class="plug" v-html="display.plug"></span>
          </div>
          <div class="titles">
            <h1>
              {{ display.nom_cpo }} {{ display.title }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mr-2"
                    x-small
                    v-on="on"
                    @click="isProblemModalOpen = !isProblemModalOpen"
                    >fas fa-exclamation-triangle</v-icon
                  >
                </template>
                <span>Signaler un problème</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-show="emsp && emsp.page"
                    x-small
                    v-on="on"
                    @click.stop="showInfos"
                    >fas fa-info</v-icon
                  >
                </template>
                <span>Informations et Tarifs</span>
              </v-tooltip>
            </h1>
            <h2>{{ display.address }}</h2>
          </div>
        </div>
        <hr />
        <div class="result-content">
          <h2 v-if="params && !params.cp_id">
            Séléctionnez un point de charge parmi les choix suivant :
          </h2>
          <h2 v-else>
            Sélectionner votre type de prise :
          </h2>
          <v-expansion-panels
            v-if="params && !params.cp_id"
            :value="panel"
            multiple
          >
            <v-expansion-panel
              v-for="(connectorList, connectorName) in connectors"
              :key="connectorName"
            >
              <v-expansion-panel-header
                v-if="connectorList && connectorList.length"
              >
                <div class="d-flex align-center">
                  <span class="cp_title mr-2">{{ connectorName }} </span>
                  <span>({{ connectorList.length }})</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="inside-panel d-flex flex-wrap">
                  <div
                    class="connector mb-2"
                    v-for="connector in connectorList"
                    :key="connector.connector_emi3"
                    @click="selectConnector(connector)"
                  >
                    <div class="d-flex align-center">
                      <div class="connector-content">
                        <div class="status d-flex align-center">
                          <p style="font-size: .9em;">
                            {{ connector.connector_emi3 }}
                          </p>
                        </div>
                        <p>
                          <span
                            class="shucko-icon"
                            v-html="connector.icon"
                          ></span>
                          {{ connector.connector_standard[0].name }}
                        </p>
                        <p>
                          Charge {{ connector.type_charge }} -
                          {{ connector.power }} kW
                        </p>
                      </div>
                      <div class="button">
                        <v-icon>fas fa-charging-station</v-icon>Charger ici
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-if="params && params.cp_id"
            :value="panel"
            multiple
          >
            <v-expansion-panel
              v-for="(connectorObj, connectorName) in connectors"
              :key="connectorName"
            >
              <v-expansion-panel-header
                v-if="connectorObj.list && connectorObj.list.length"
              >
                <div class="d-flex align-center">
                  <span class="connecteurs mr-6">
                    <span class="plug-format" v-html="connectorObj.icon"></span>
                  </span>
                  <span class="cp_title mr-2">{{ connectorName }} </span>
                  <span>({{ connectorObj.list.length }})</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="inside-panel d-flex flex-wrap">
                  <div
                    class="connector mb-2"
                    v-for="connector in connectorObj.list"
                    :key="connector.connector_emi3"
                    @click="selectConnector(connector)"
                  >
                    <div class="d-flex align-center">
                      <div class="connector-content">
                        <div class="status d-flex align-center">
                          <p style="font-size: .9em;">
                            {{ connector.connector_emi3 }}
                          </p>
                        </div>

                        <p>
                          Charge {{ connector.type_charge }} -
                          {{ connector.power }} kW
                        </p>
                      </div>
                      <div class="button">
                        <v-icon>fas fa-charging-station</v-icon>Charger ici
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <p v-if="type == 'charging point' || type == 'connector'">
            <b v-if="display.type_charge">TYPE DE RECHARGE : </b>
            {{ display.type_charge && display.type_charge.toUpperCase() }} -
            <b v-if="display.power">PUISSANCE :</b> {{ display.power }} kW
          </p>
        </div>
      </div>
    </div>
    <v-dialog v-model="isProblemModalOpen" max-width="320">
      <v-card>
        <section class="problem-section py-8">
          <h4>Signaler un problème</h4>
          <div v-if="problemSent">
            <p
              style="color:green; font-size:15px"
              class="mb-0 mt-2 d-flex flex-column align-center text-center"
            >
              <v-icon color="green" small class="mr-2 d-block mb-4"
                >fas fa-check</v-icon
              ><span
                >Votre signalement a bien été pris en compte<br />Nous vous en
                remercions.</span
              >
            </p>
          </div>
          <v-form
            v-model="isFormValid"
            @submit.prevent="validate"
            lazy-validation
            ref="form"
          >
            <v-text-field
              class="mt-4"
              outlined
              label="Votre adresse email"
              v-model="email"
              validate-on-blur
              :rules="[...requiredRule, ...emailRule]"
            ></v-text-field>
            <v-select
              item-text="label"
              validate-on-blur
              item-value="id"
              v-model="category"
              outlined
              :items="categorieList"
              :reduce="problem => problem.id"
              label="Catégorie"
              :rules="[...requiredRule]"
            />

            <label class="label">Description</label>
            <quill-editor
              ref="quill"
              v-model="description"
              :options="editorOptions"
              class="mb-4 editor"
            />

            <v-btn type="submit" small>Valider</v-btn>
          </v-form>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      data: null,
      params: null,
      type: null,
      display: {
        nom_cpo: null,
        title: "",
        address: "",
        power: null,
        connectors: null,
        charging_points: null,
        type_charge: null
      },
      emsp_options: null,
      emsp: null,
      isLoading: false,
      isReduce: false,
      isProblemModalOpen: false,
      isFormValid: false,
      categorieList: [
        { id: 1, label: "Borne en panne" },
        { id: 2, label: "Borne vandalisée" },
        { id: 3, label: "Autre anomalie sur la borne" }
      ],
      requiredRule: [v => !!v || "Veuillez remplir ce champ"],
      emailRule: [v => /.+@.+/.test(v) || "Vérifiez le format du mail"],
      description: null,
      category: null,
      email: null,
      editorOptions: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              { list: "ordered" },
              { list: "bullet" },
              { color: [] },
              { background: [] },
              "link"
            ]
          ]
        },
        placeholder: "Décrivez le problème"
      },
      connectors: null,
      problemSent: false,
      panel: []
    };
  },
  created() {
    // this.$store.dispatch("station/getNewStations", false);
  },
  async mounted() {
    this.params = this.$router.history.current.params;
    if (this.params.emi3 && this.params.cp_id && this.params.connector_id) {
      let splittedStationId = this.params.emi3.split("*");
      splittedStationId[2] = splittedStationId[2].replace("P", "E");
      let chargingPointEmi = splittedStationId.join("*");
      window.location.href =
        "https://pay.chargedrive.com/" +
        chargingPointEmi +
        "*" +
        this.params.cp_id +
        "*" +
        this.params.connector_id + "*";
      return;
    }

    await this.setData();
    if (this.params.cp_id) this.setConnectorsByType();
  },
  computed: {
    ...mapGetters("emsp", ["emsps"])
  },
  methods: {
    async setData() {
      let stationWhere = {};
      let cpWhere = {};
      if (this.params.cp_id) {
        let splittedStationId = this.params.emi3.split("*");
        splittedStationId[2] = splittedStationId[2].replace("P", "E");
        let chargingPointEmi = splittedStationId.join("*");
        stationWhere = {
          charging_points: {
            connectors: { r_station_id: { _eq: chargingPointEmi } }
          }
        };
        cpWhere = {
          charging_point_id: {
            _eq: this.params.emi3 + "*" + this.params.cp_id
          }
        };
      } else if (this.params.emi3) {
        stationWhere = {
          charging_points: {
            connectors: { r_station_id: { _eq: this.params.emi3 } }
          }
        };
        cpWhere = null;
      }

      await this.$store.dispatch("scan/getStation", { stationWhere, cpWhere });
      this.data = this.$store.getters["scan/station"].station[0];
      if (!this.data) {
        let splittedStationId = this.params.emi3.split("*");
        splittedStationId[2] = splittedStationId[2].replace("P", "E");
        let chargingPointEmi = this.params.cp_id ? `${splittedStationId.join("*")}*${this.params.cp_id}` : splittedStationId.join("*");
        window.location.href = "https://pay.chargedrive.com/" + chargingPointEmi + "*";
      }
      this.display = {
        title: this.data.station_name,
        address:
          (this.data.street_number || "") +
          " " +
          (this.data.street_name || "") +
          " " +
          (this.data.zip_code || "") +
          " " +
          (this.data.town || ""),
        charging_points: this.data.charging_points
      };

      this.setIconsConnectors();
      this.setConnectors();
      if (this.data.cpo.tariffs.length) {
        this.emsp = this.emsps.find(
          emsp => emsp.emsp_id === this.data.cpo.tariffs[0].emsp_id
        );
      }
    },
    setIconsConnectors() {
      this.data.charging_points?.forEach(cp => {
        cp.connectors.forEach(connector => {
          connector.icon = this.switchIcons(connector.standard);
        });
      });
    },
    switchIcons(data) {
      let icon;
      switch (data) {
        case "SCHUKO":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_A":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_B":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_C":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_D":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_E":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_F":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_G":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_H":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_I":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_J":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_K":
          icon = "&#xe80d;";
          break;
        case "DOMESTIC_L":
          icon = "&#xe80d;";
          break;
        case "TYPE_1":
          icon = "&#xe80e;";
          break;
        case "IEC_62196_T1":
          icon = "&#xe80e;";
          break;
        case "IEC_62196_T2":
          icon = "&#xe80f;";
          break;
        case "TYPE_2":
          icon = "&#xe80f;";
          break;
        case "TYPE_2_ATCH":
          icon = "&#xe80f;";
          break;
        case "TYPE_3":
          icon = "&#xe810;";
          break;
        case "IEC_62196_T3A":
          icon = "&#xe810;";
          break;
        case "IEC_62196_T3C":
          icon = "&#xe810;";
          break;
        case "CHADEMO":
          icon = "&#xe803;";
          break;
        case "CCS":
          icon = "&#xe80c;";
          break;
        case "IEC_62196_T2_COMBO":
          icon = "&#xe80c;";
          break;
        case "IEC_62196_T1_COMBO":
          icon = "&#xe80c;";
          break;
        default:
          break;
      }
      return icon;
    },
    async validate() {
      if (this.isFormValid) {
        await this.$store.dispatch("station/addProblemeReport", {
          station_id: this.data.station_id,
          email: this.email,
          category: this.category,
          description: this.description
        });
        this.description = null;
        this.category = null;
        this.email = null;
        this.problemSent = true;
        setTimeout(() => {
          this.problemSent = false;
        }, 2500);
      }
    },
    async selectConnector(connector) {
      window.location.href = "https://pay.chargedrive.com/" + connector.connector_emi3.slice(0, -1);
    },
    setConnectors() {
      this.connectors = {};

      let charging_points = [...this.data.charging_points];
      charging_points.forEach(cp => {
        cp.connectors.forEach(connector => {
          if (!this.connectors[connector.charging_point_id]) {
            this.connectors[connector.charging_point_id] = [connector];
          } else this.connectors[connector.charging_point_id].push(connector);
        });
      });
    },
    setConnectorsByType() {
      let { charging_points } = this.data;
      let connectors = {
        Domestique: { icon: "&#xe80d;", list: [] },
        "Type 1": { icon: "&#xe80e;", list: [] },
        "Type 2": { icon: "&#xe80f;", list: [] },
        "Type 3": { icon: "&#xe810;", list: [] },
        Chademo: { icon: "&#xe803;", list: [] },
        Combo: { icon: "&#xe80c;", list: [] }
      };

      charging_points.forEach(cp => {
        cp.connectors.forEach(connector => {
          let connectorObj = {
            ...connector,
            type_charge: cp.type_charge,
            status: cp.status
          };
          if (
            connector.standard === "SCHUKO" ||
            connector.standard.includes("DOMESTIC")
          )
            connectors["Domestique"].list.push(connectorObj);
          else if (
            connector.standard === "TYPE_1" ||
            connector.standard === "IEC_62196_T1"
          )
            connectors["Type 1"].list.push(connectorObj);
          else if (
            connector.standard === "IEC_62196_T2" ||
            connector.standard.includes("TYPE_2")
          )
            connectors["Type 2"].list.push(connectorObj);
          else if (
            connector.standard === "TYPE_3" ||
            connector.standard.includes("_T3")
          )
            connectors["Type 3"].list.push(connectorObj);
          else if (connector.standard === "CHADEMO")
            connectors["Chademo"].list.push(connectorObj);
          else if (
            connector.standard === "CCS" ||
            connector.standard.includes("_COMBO")
          )
            connectors["Combo"].list.push(connectorObj);
        });
      });

      this.connectors = Object.fromEntries(
        Object.entries(connectors).filter(
          ([name, connector]) => name && connector.list.length > 0
        )
      );

      let index = 0;
      for (let connector in this.connectors) {
        if (this.connectors[connector].list.length === 1) {
          this.panel.push(index);
          index++;
        }
      }

      if (
        Object.entries(this.connectors).length === 1 &&
        this.connectors[Object.entries(this.connectors)[0][0]].list.length === 1
      ) {
        this.selectConnector(
          this.connectors[Object.entries(this.connectors)[0][0]].list[0]
        );
      }

      // let connectorTypes: ["SCHUKO/DOMESTIC", "&#xe80d;", "TYPE_1", "&#xe80e;", "TYPE_2", "&#xe80f;", "TYPE_3", "&#xe810;", "CHADEMO", "&#xe803;", "CCS", "&#xe80c;"]
    },
    showInfos() {
      window.open(this.emsp.page, "_blank");
    }
  }
};
</script>
<style lang="scss">
html,
body {
  max-height: 100vh !important;
  #tarteaucitronAlertSmall {
    z-index: -1;
  }
  overflow: hidden;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.v-application--wrap {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
}
.fragment {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.scan-result {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background: #96c01f;
  h1 {
    font-size: 1.8em;
    line-height: 1.25em;
  }
}

a {
  padding: 10px 20px;
  border-radius: 100px;
  color: white !important;
  background-color: #212121;
  text-decoration: none;
  &.again {
    position: absolute;
    top: 4vh;
    z-index: 9999999999;
    left: calc(50% - 125px);
    color: white;
    background-color: #5886cd;
    width: 250px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding-top: 5px;
      margin-right: 10px;
    }
  }
  &.paiement {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: white;
    span {
      display: inline-flex;
      justify-content: center;
      margin-right: 10px;
      align-items: center;
      background: #f3e050;
      color: black;
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }
    background-color: #212121;
  }
}
.result {
  padding: 30px 25px;
  position: fixed;
  overflow: scroll;
  background-color: white;
  width: 100vw;
  height: 85vh;
  top: 15vh;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.1);

  &.show {
    animation: slideUp 1s forwards;
  }
  &.hide {
    animation: slideDown 1s forwards;
  }
  .center {
    text-align: center;
    padding: 20px;
  }

  .result-header {
    display: flex;
    align-items: center;
    span.plug {
      font-size: 30px;
      font-family: "SCHUKO";
      margin-right: 20px;
    }
  }
  h2 {
    text-transform: uppercase;
    font-weight: lighter;
    margin-bottom: 20px;
    font-size: 14px;
  }
  hr {
    border: 1px solid #eee;
    margin-bottom: 10px;
  }
  .center {
    text-align: center;
    padding: 20px;
  }
  .result-content {
    .tarifs {
      padding: 20px 0;
    }
    .cp_title {
      font-weight: bold;
    }
    .inside-panel {
      padding: 10px;
      .connector {
        font-size: 15px;
        width: 100%;
        padding: 0 0 0 15px;
        @media (min-width: 1280px) {
          width: 30%;
          margin-right: 8px !important;
        }
        background: #eee;
        border-radius: 10px;
        .connector-content {
          padding: 10px 0;
        }
        .status {
          margin-bottom: 0.5em;
        }
        p {
          margin: 0;
        }
        .button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          text-transform: uppercase;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.54);
          font-size: 0.85em;
          background: #ddd;
          height: 100px;
          padding: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          .icon {
            margin-bottom: 0.25em;
            margin-left: 0.25em;
          }
        }
        .connector-link {
          cursor: pointer;
        }
        .dot {
          content: "";
          background: #ccc;
          height: 12px;
          width: 12px;
          padding: 0;
          font-size: 1rem;
          box-sizing: border-box;
          border-radius: 12px;
          display: block;
          margin-right: 1em;
        }
      }
    }
    .connecteurs {
      font-size: 20px;
      font-family: "SCHUKO";
      span {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
      img {
        width: 7;
        height: 50px;
      }
    }
  }

  @keyframes slideDown {
    from {
      top: 10vh;
    }
    to {
      top: 70vh;
    }
  }
  @keyframes slideUp {
    from {
      top: 70vh;
    }
    to {
      top: 15vh;
    }
  }
}
.shucko-icon {
  font-family: "SCHUKO";
}
</style>
